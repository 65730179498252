<template>
  <div>
    <div class="top">
      <el-row type="flex" align="middle">
        <el-col :span="1.6">
          <el-avatar class="school_logo" :size="60" style="margin-top: 10px;margin-right: 10px;"
            :src="collegeData.logo" />
        </el-col>
        <el-col :span="17">
          <p class="row">
            <span style="margin-right: 10px;font-size: 18px;">{{ collegeData.name }}</span>
            <span style="color:#adadad;font-size: 16px;">办学类型：{{ collegeData.nature }}</span>
            <span v-if="collegeData.school_tip" style="color:#adadad;font-size: 16px;">
              | {{ collegeData.school_tip }}
            </span>
            <span v-if="collegeData.title == '中外合作办学'" style="color:#adadad;margin-left: 20px;">[{{ collegeData.title
              }}]</span>
          </p>
          <p style="color:#adadad;margin-top: 10px;font-size: 16px;  white-space: nowrap;overflow: hidden;
  text-overflow: ellipsis; ">
            <span>院校代码：<span style="color: #000000;">{{ collegeData.school_id }}</span></span>
            <span style="margin-left: 15px;">2025招生计划： <span style="color: #000000;"> {{ collegeData.plan_num }}</span>
            </span>
            <span style="margin-left: 15px;">2025选科要求：<span style="color: #000000;">{{ collegeData.demand
                }}</span></span>
            <span v-if="collegeData.city_name" style="margin-left: 25px; white-space: nowrap">地址：
              <span style="color: #000000;"> {{ collegeData.address }}</span>
            </span>
          </p>
        </el-col>
      </el-row>

      <div class="school_group">
        <el-row type="flex" align="middle" :gutter="10">
          <el-col :span="2">
            <div class="title">
              院校<br> 专业组
            </div>
          </el-col>
          <el-col :span="14" v-if="this.select_code">
            <div :class="{ act: item.select_code === select_code, group_item: true }"
              v-for="(item, i) in CollegeListData" :key="i" @click="chooseGroup(item)">
              <div>
                <p>[
                  {{ item.title }}
                  ]
                </p>
                <p class="tip">难</p>
              </div>
            </div>

          </el-col>
          <el-col :span="8" style="text-align: right;">
            <el-popover popper-class="popover-f" style="margin-right: 60px;" placement="bottom" trigger="hover">
              <div class="popover-box">
                <p class="box-title">志愿位置</p>
                <span v-for="h in len" :key="h" class="box-item" @click="chooseCollege(collegeData, h)">
                  <span v-if="record.select_code[h - 1] != ''" class="box-item-box"
                    style="background: #fbbfbb; border: #fbbfbb;">{{ h }}</span>
                  <span v-else class="box-item-box">{{ h }}</span>
                </span>
              </div>

              <el-button size="medium" v-if="record.select_code.indexOf(collegeData.select_code) > -1" slot="reference"
                type="primary">
                志愿{{ record.select_code.indexOf(collegeData.select_code) + 1 }}
              </el-button>
              <el-button v-else slot="reference" size="medium">填报为</el-button>
            </el-popover>

            服从调剂：
            <el-radio-group v-model="tiaoji" style="margin-right: 20px;" @change="getTiaoji">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="score">
      <el-row style="margin-top: 20px" :gutter="20" class="plan_tab1">
        <el-col :span="12">

          <el-table :data="planData" class="tab_plan" height="325"
            :header-cell-style="{ background: 'white', color: '#555', padding: '3px 0' }"
            :cell-style="{ background: 'white', padding: '3px 0' }" style="width: 100%" border v-loading='loading'>
            <el-table-column label="今年招生计划">
              <el-table-column prop="specialty_id" label="代码" width="49px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.specialty_id | interpo }}
                </template>
              </el-table-column>
              <el-table-column prop="specialty_name" label="专业名称" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="direction" label="专业备注" show-overflow-tooltip align="center" width="60">
              </el-table-column>
              <el-table-column prop="plan_num" label="招生计划" align="center" width="60">
              </el-table-column>
              <el-table-column prop="tuition" label="学费/年" align="center" width="100"> </el-table-column>
              <el-table-column label="操作" align="center" width="80">
                <template slot-scope="scope">
                  <el-button v-if="spList.indexOf(scope.row.id) > -1" @click="chooseSpecialty(scope.row)" size="mini"
                    type="primary">
                    已选择 {{ spList.indexOf(scope.row.id) + 1 }}
                  </el-button>
                  <el-button v-else @click="chooseSpecialty(scope.row)" size="mini">
                    填报
                  </el-button>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </el-col>

        <el-col :span="12">
          <MojarOld :searchKey='searchKey' :userInfo="userInfo" :oldLine="oldLine" :collegeData="collegeDatas" />
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import MojarOld from "./MojarOld";
import { mapState } from "vuex";
export default {
  props: ["collegeDatas", 'userInfo', 'oldLine'],
  name: "",
  components: {
    MojarOld
  },
  data() {
    return {
      planData: [],
      loading: true,
      searchKey: "",
      tiaoji: 1,
      spList: [],
      select_code: 1,
      CollegeListData: [],
      currentOffset: 0,
      windowSize: 4,
      paginationFactor: 305,
      len: 48,
      collegeData: {}
    };
  },
  computed: {
    ...mapState(["record"]),
  },
  mounted() {
    this.collegeData = this.collegeDatas
    this.select_code = this.collegeData.select_code
    this.new_batch = this.collegeData.new_batch

    //获取学校所有组
    this.GetCollegeGroup()

    let index = this.record.select_code.indexOf(this.select_code)

    //设置spList
    if (index > -1) {
      this.tiaoji = this.record.table[index].tiaoji
      if (this.record.specialty[index].length > 0) {
        this.spList = this.record.specialty[index].map(v => { return v.id })
      } else {
        this.spList = []
      }
      this.$forceUpdate
    }

    //获取批次

    this.getPlan();
  },

  methods: {
    //搜索院校
    GetCollegeGroup() {
      this.$fecth
        .post("volunteer_demand/GetCollegeGroup", {
          name: this.collegeData.name,
          subject_id: this.userInfo.subject_id,
          subject: this.userInfo.subject,
          new_batch: this.new_batch,
        })
        .then((res) => {
          res = res.data;
          this.CollegeListData = res.lists;
        });
    },

    //选择组
    chooseGroup(item) {
      this.select_code = item.select_code
      this.collegeData = item

      let index = this.record.select_code.indexOf(this.select_code)
      //设置spList
      if (index > -1) {
        if (this.record.specialty[index].length > 0) {
          this.spList = this.record.specialty[index].map(v => { return v.id })
        } else {
          this.spList = []
        }

        this.$forceUpdate
      }
      this.getPlan()
    },

    //获取数据
    getPlan() {
      if (this.select_code) {
        this.loading = true;
        this.$fecth
          .post("volunteer_demand/getPlan", {
            select_code: this.select_code,
            new_batch: this.new_batch,
          })
          .then((res) => {
            res = res.data;
            this.planData = res;
            this.loading = false;
          });
      }
    },

    //选择专业
    chooseSpecialty(item) {

      //判断是否选择志愿位置
      let index = this.record.select_code.indexOf(this.select_code);

      if (index > -1) {

        //判断这个专业是否填报过
        let sp_id = this.spList.indexOf(item.id);

        //如果填报过就删除
        if (sp_id > -1) {
          this.record.specialty[index].splice(sp_id, 1)
          this.spList.splice(sp_id, 1);
        } else {
          //判断是否已经选了6个志愿
          let splen = this.spList.length;
          if (splen == 6) {
            this.$alert("每个志愿最多填报5个专业！", "提示", {
              confirmButtonText: "确定",
            });
            return false;
          }

          this.searchKey = item.specialty_name

          this.record.specialty[index].push(item);
          this.spList.push(item.id);
          this.$store.commit('setRecord', this.record)
        }
        this.updateReport()
      } else {
        this.$alert("请先选择院校", "提示", {
          confirmButtonText: "确定",
        }).catch((msg) => {
          console.log(msg);
        });
      }
    },

    updateReport() {
      this.$fecth
        .post("volunteer/updateReport", {
          user_id: this.$route.query.id,
          subject: this.userInfo.subject,
          new_batch: this.new_batch,
          record: JSON.stringify(this.record)
        })
    },

    changeAlert(item) {
      item.tiaoji = 1;
      this.$forceUpdate();
    },

    //填报为
    chooseCollege(item, i) {
      item.tiaoji = 1
      // console.log(item,i)
      // return false
      let select_code_id = this.record.select_code.indexOf(item.select_code)

      //如果这个学校填过就删除
      if (select_code_id > -1) {
        this.record.table[select_code_id] = {
          name: "暂无",
        }
        this.record.select_code[select_code_id] = ''
        this.record.specialty[select_code_id] = []
      }

      //添加这个位置的志愿
      this.record.table[i - 1] = item
      this.record.select_code[i - 1] = item.select_code
      this.record.specialty[i - 1] = []

      this.spList = []

      //更新数据流
      this.$store.commit('setRecord', this.record)
      this.$emit('refush', this.record)
      this.$forceUpdate()


      this.updateReport()
    },

    getTiaoji() {
      let index = this.record.select_code.indexOf(this.select_code);
      if (index > -1) {
        this.record.table[index].tiaoji = this.tiaoji
      }
      this.updateReport()
    },
  },
};
</script>

<style scoped lang='less'>
// .box_22 {
//   display: flex;
//   overflow: hidden;
//   width: 400px;
//   height: 100px;
// }

// .box_11 {
//   display: flex;
//   flex-direction: row;
//   width: 200px;
//   background: gray;
//   display: inline-block;
//   margin-right: 20px;
//   height: 100px;
// }

.top {
  background: #CBEAFF;
  padding: 15px 20px;
  position: relative;
}


.row {
  font-size: 25px;
  color: black;
}

.school_group {
  margin-top: 20px;

  .title {
    font-size: 22px;
    text-align: center;
  }

  .group_item {
    margin-right: 20px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid red;
    display: inline-block;
    padding: 5px 5px;
    color: red;
    width: 80px;
    vertical-align: bottom;
    background: white;

    .tip {
      background: red;
      color: white;
      display: inline-block;
      padding: 1px 15px;
      border-radius: 3px;
      margin-top: 4px
    }
  }

  .tianbao {
    width: 25px;
    padding: 5px 0;
    display: inline-block;
    line-height: 107%;
    vertical-align: bottom;
    border: 1px solid red;
    color: red;
    display: inline-block;
    border-left: 0;
    border-radius: 0 5px 5px 0;
  }

  .act {
    background: #FFE2E5;
    display: inline-block;
  }
}



.info {
  font-size: 14px;
  position: relative;

  span {
    display: inline-block;
  }
}
</style>

<style lang="less">
.tab_plan {

  .el-table td,
  .el-table th {
    padding: 0px;
  }
}

.score {
  padding: 5px 30px 60px;
}
</style>